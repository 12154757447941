define("discourse/plugins/upgraded-reviewables/discourse/initializers/init-new-reviewable-channels", ["exports", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/lib/text"], function (_exports, _ajax, _pluginApi, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let updateReviewable = data => {
    let reviewableId = data.reviewable_id;
    let reviewableMapItems = document.querySelectorAll('.reviewable-map-item');
    let reviewableItems = document.querySelectorAll('.reviewable-item');
    let reviewableKey = null;
    for (let i = 0; i < reviewableMapItems.length; i++) {
      if (+reviewableMapItems[i].dataset.reviewableId === reviewableId) {
        reviewableKey = i;
        break;
      }
    }
    if (reviewableKey !== null && reviewableItems[reviewableKey]) {
      if (data.action === 'edit') {
        (0, _ajax.ajax)(`/updated-reviewable/${reviewableId}`).then(async response => {
          if (response?.reviewable_queued_post?.payload?.raw) {
            let reviewableBody = reviewableItems[reviewableKey].querySelector('.post-body div');
            reviewableBody.innerHTML = await (0, _text.cook)(response.reviewable_queued_post.payload.raw);
          }
        });
      }
      if (data.action === 'delete') {
        reviewableMapItems[reviewableKey].remove();
        reviewableItems[reviewableKey].remove();
      }
    }
  };
  var _default = _exports.default = {
    name: 'init-new-reviewable-channels',
    after: "message-bus",
    initialize(container) {
      let messageBusService = container.lookup("service:message-bus");
      (0, _pluginApi.withPluginApi)("0.12.1", api => {
        api.onPageChange((url, title) => {
          let reviewableUpdateChannel = messageBusService.callbacks.find(callback => callback.channel.includes('/reviewable-update'));
          if (reviewableUpdateChannel) {
            messageBusService.unsubscribe(reviewableUpdateChannel.channel, reviewableUpdateChannel.func);
          }
          let topicController = container.lookup("controller:topic");
          let topic = topicController.get('model');
          if (topic) {
            messageBusService.subscribe(`/reviewable-update/${topic.get('id')}`, updateReviewable);
          }
        });
      });

      // Event for GA
      document.addEventListener('mousedown', e => {
        const closestCreateBtn = e.target.closest('button.create');
        if (!closestCreateBtn || !closestCreateBtn.closest('#reply-control')) {
          return;
        }
        const buttonSpan = closestCreateBtn.querySelector('.d-button-label');
        if (!buttonSpan) {
          return;
        }
        buttonSpan.classList.remove('d-button-label');
        buttonSpan.classList.add('d-button-label-success');
      });
    }
  };
});