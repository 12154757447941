define("discourse/plugins/upgraded-reviewables/discourse/templates/connectors/topic-above-posts/reviewable-map-block", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.pending_posts}}
    <div class="reviewable-map" style="display: none">
      {{#each this.model.pending_posts as |pending|}}
        <div class="reviewable-map-item" data-reviewable-id={{pending.id}}></div>
      {{/each}}
    </div>
  {{/if}}
  */
  {
    "id": "V8MW7b/4",
    "block": "[[[41,[30,0,[\"model\",\"pending_posts\"]],[[[1,\"  \"],[10,0],[14,0,\"reviewable-map\"],[14,5,\"display: none\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"model\",\"pending_posts\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"reviewable-map-item\"],[15,\"data-reviewable-id\",[30,1,[\"id\"]]],[12],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"pending\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "discourse/plugins/upgraded-reviewables/discourse/templates/connectors/topic-above-posts/reviewable-map-block.hbs",
    "isStrictMode": false
  });
});